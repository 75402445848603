<template>
  <v-card  class="border-radius-medium p-4">
    <v-list-item>
      <h4 class="secondary--text">Categorías</h4>
    </v-list-item>
    <v-skeleton-loader
      v-bind="skeletonAttrs"
      type="article, actions, list-item-three-line"
      v-if="this.loading"
    ></v-skeleton-loader>
    <v-expansion-panels
      accordion
      multiple
      flat
      hover
      :mandatory="!$vuetify.breakpoint.mobile"
      v-if="this.facets.length"
    >
      <v-expansion-panel v-for="(facet, i) in this.facets" :key="i">
        <v-expansion-panel-header  class="border-bottom">
          <span class="font-weight-bold">{{ $t(facet.title) }}</span>
          <template v-slot:actions>
            <v-icon color="#59D79D"> mdi-menu-down </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-treeview
            v-if="facet.type === 'checkbox'"
            @input="facetSelectionUpdated"
            :items="facet.facet_options"
            v-model="facetsSelected[facet.field_name]"
            selectable
            dense
            return-object
            selected-color="primary"
            item-text="display_name"
            item-key="id"
          ></v-treeview>
          <v-autocomplete
            v-if="facet.type === 'dropdown'"
            class="my-5"
            :label="$t('selectSkill')"
            @change="facetSelectionUpdated"
            :items="facet.facet_options"
            v-model="facetsSelected[facet.field_name]"
            color="#59D79D"
            item-color="#59D79D"
            chips
            return-object
            hide-details
            item-text="display_name"
            item-value="id"
            multiple
            solo
            full-width
            deletable-chips
            :search-input.sync="searchInput"
          ></v-autocomplete>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import axios from 'axios';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import { FACET_CATEGORY_FIELD_NAME, FACET_SKILLS_FIELD_NAME, FACET_CITY_FIELD_NAME } from '@/misc/constants';
import userDataMixin from '@/mixins/userDataMixin';

export default {
  name: 'FacetOptions',
  props: ['updateResults', 'urlParams', 'searchText'],
  mixins: [errorDialog, userDataMixin],
  data () {
    return {
      facets: [],
      facetsSelected: {},
      searchInput: '',
      skeletonAttrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
      },
      loading: true
    };
  },
  created () {
    this.getFacets(this.urlParams);
  },
  methods: {
    facetSelectionUpdated () {
      this.searchInput = '';
      let facetsSelectedFlat = [];
      for (let prop in this.facetsSelected) {
        facetsSelectedFlat = facetsSelectedFlat.concat(
          this.facetsSelected[prop]
        );
      }
      this.updateResults(facetsSelectedFlat);
    },
    searchInFacet (facetOptions, categoryId) {
      let facetOptionFound = facetOptions.find(
        (facetOption) => facetOption.value.toString() === categoryId.toString()
      );
      if (facetOptionFound) {
        return facetOptionFound;
      }

      let i = 0;
      while (i < facetOptions.length && !facetOptionFound) {
        const facetOption = facetOptions[i];
        if (facetOption.children && facetOption.children.length) {
          facetOptionFound = this.searchInFacet(
            facetOption.children,
            categoryId
          );
        }
        i++;
      }

      return facetOptionFound;
    },
    preselectFacetsIfApply (categoryIds) {
      // TODO - make this logic generic in the future, this is to handle category page navigation into user search page
      if (categoryIds && categoryIds.length) {
        categoryIds.forEach((categoryId) => {
          this.facets
            .filter((facet) => facet.field_name === 'categories.id')
            .forEach((facet) => {
              const facetOptionFound = this.searchInFacet(
                facet.facet_options,
                categoryId
              );
              if (facetOptionFound) {
                this.facetsSelected['categories.id'].push(facetOptionFound);
              }
            });
        });
      }
      this.sendDataLayerInfo();
    },
    sendDataLayerInfo () {
    const categories = this.facetsSelected[FACET_CATEGORY_FIELD_NAME].map(item => item.display_name);
    const skills = this.facetsSelected[FACET_SKILLS_FIELD_NAME].map(item => item.display_name);
    const cities = this.facetsSelected[FACET_CITY_FIELD_NAME].map(item => item.display_name);
      this.trackUserData('load_content', {
        pageType: 'profiles',
        categories,
        modalidades: skills,
        localidades: cities
      });
    },
    getFacets (categoryIds) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/facets?entity=user`;
      this.loading = true;
      axios
        .get(url)
        .then((response) => {
          const facets = response.data.data;
          facets.forEach((facet) => {
            // one array per each facet
            this.facetsSelected[facet.field_name] = [];

            facet.facet_options.forEach((facetOption) => {
              facetOption.field_name = facet.field_name;

              facetOption.children = facetOption.children.map((item) => {
                item.field_name = facet.field_name;
                return item;
              });
            });
            this.facets.push(facet);
          });
          this.loading = false;
          // check for pre-selected categories
          this.preselectFacetsIfApply(categoryIds);
        })
        .catch((error) => {
          this.showError(error);
        });
    }
  }
};
</script>
